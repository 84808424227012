const VALID_NEWS_TYPES = [
  'FESTIVAL',
  'GENERAL',
  'INTERNATIONAL',
  'LOCAL',
  'MAGAZINE',
  'MIXOFTHEDAY',
  'PLAYLIST',
  'RAONRA',
  'RAPRO',
  'TECH',
]

/**
 * Takes a news type from GraphQL and maps it to a react-intl-friendly key
 * the keys are stored in /src/messages/magazine.js as lowercase versions of the news types.
 */

const formatNewsType = (type) =>
  VALID_NEWS_TYPES.includes(type) ? type.toLowerCase() : 'category'

export { VALID_NEWS_TYPES }
export default formatNewsType
