import { Alignment, Box, variants } from '@resident-advisor/design-system'
import PropTypes, { InferProps } from 'prop-types'
import Link from 'components/generic/link'
import Date from 'components/generic/date'
import CmsContent from 'components/generic/cms-content'
import CardMeta from 'components/generic/card-meta'
import ResponsiveImage from 'components/generic/responsive-image'
import ASPECT_RATIO from 'lib/aspectRatio'
import { newsByIdRoute } from 'lib/routes'
import useNewsTypeTitle from 'hooks/useNewsTypeTitle'
import testIds from 'enums/testIds'

type CardMetaItem =
  | {
      text: string
      href?: string
    }
  | {
      date: string
      dateFormat: string
    }

const LatestNews = ({
  imageUrl,
  type,
  date,
  dateFormat = Date.Formats.Time,
  title,
  contentUrl,
  blurb,
  formatTypeUrl,
}: LatestNewsProps) => {
  const newsTypeTitle = useNewsTypeTitle(type)

  const metaItems: CardMetaItem[] = [{ date, dateFormat }]

  if (type) {
    metaItems.push({
      text: newsTypeTitle,
      href: formatTypeUrl(type),
    })
  }

  return (
    <Alignment
      flexDirection="column"
      role="listitem"
      data-testid={testIds.latestNewsCard}
    >
      <Link noStyle {...newsByIdRoute(contentUrl)}>
        <ResponsiveImage
          alt={title}
          url={imageUrl}
          aspect={ASPECT_RATIO['16:9']}
          sizes={{ m: '50vw' }}
        />
      </Link>
      <CardMeta items={metaItems} mt={3} />
      <Box mb={2}>
        <Link
          color="primary"
          variant={variants.text.heading.l}
          {...newsByIdRoute(contentUrl)}
        >
          {title}
        </Link>
      </Box>
      <CmsContent content={blurb} />
    </Alignment>
  )
}

const LatestNewsPropTypes = {
  imageUrl: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  type: PropTypes.string,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  contentUrl: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  formatTypeUrl: PropTypes.func.isRequired,
}

LatestNews.propTypes = LatestNewsPropTypes

type LatestNewsProps = InferProps<typeof LatestNewsPropTypes>

export default LatestNews
